/**
 * Module to initialize the HlsPlayer feature if active on the page.
 */

// eslint-disable-next-line import/group-exports
export async function initializeHlsPlayer() {
    /* eslint-disable new-cap*/
    // Select Video Elements
    const videoDesktop = document.querySelector('[data-video-desktop]'),
        videoMobile = document.querySelector('[data-video-mobile]'),
        playPauseBtn = document.querySelector('[data-play-pause-btn]'),
        muteUnmuteBtn = document.querySelector('[data-mute-unmute-btn]');

    if (!videoDesktop && !videoMobile) {
        // No video elements found, exit early
        return;
    }

    // Dynamically import the HlsPlayer module
    let hlsPlayer,
        activeVideo = null,
        inactiveVideo = null,
        playerInstance = null,
        lastPlaybackPosition = 0,
        globalState = {
            isPlaying: true,
            isMuted: true,
        },
        hlsPlayerResizeTimeout; // Combine with all previous 'let' declarations

    try {
        const module = await import(/* webpackMode: "lazy" */ './hlsPlayer.js');

        hlsPlayer = module.HlsPlayer; // Use named import
    } catch (error) {
        console.error('Failed to load HlsPlayer module:', error);
        return;
    }

    /**
     * Utility function to check if an element is visible.
     * @param {HTMLElement} element - The DOM element to check.
     * @returns {boolean} - True if visible, false otherwise.
     */
    function hlsPlayerIsVisible(element) {
        return element && getComputedStyle(element).display !== 'none';
    }

    /**
     * Determines the currently active and inactive video elements based on visibility.
     * @returns {Object} - An object containing the active and inactive video elements.
     */
    function hlsPlayerDetermineActiveVideo() {
        if (hlsPlayerIsVisible(videoDesktop)) {
            return { active: videoDesktop, inactive: videoMobile };
        } else if (hlsPlayerIsVisible(videoMobile)) {
            return { active: videoMobile, inactive: videoDesktop };
        }
        return { active: null, inactive: null };
    }

    /**
     * Updates the play/pause button UI based on the current globalState.
     */
    function hlsPlayerUpdatePlayPauseButton() {
        if (!playPauseBtn) {
            return;
        }

        playPauseBtn.classList.toggle('is-paused', !globalState.isPlaying);

        const label = globalState.isPlaying ? 'Pause Video' : 'Play Video';

        playPauseBtn.setAttribute('aria-label', label);
    }

    function hlsPlayerUpdateMuteUnmuteButton() {
        if (!muteUnmuteBtn) {
            return;
        }

        // Toggle 'is-muted' class based on the mute state
        muteUnmuteBtn.classList.toggle('is-muted', globalState.isMuted);

        // Update the aria-label for accessibility
        const label = globalState.isMuted ? 'Unmute Video' : 'Mute Video';

        muteUnmuteBtn.setAttribute('aria-label', label);
    }

    function hlsPlayerHandlePlay() {
        globalState.isPlaying = true;
        hlsPlayerUpdatePlayPauseButton();
    }

    function hlsPlayerHandlePause() {
        globalState.isPlaying = false;
        hlsPlayerUpdatePlayPauseButton();
    }

    function hlsPlayerSyncGlobalStateWithVideo(video) {
        // Remove existing listeners to prevent duplicate bindings
        video.removeEventListener('play', hlsPlayerHandlePlay);
        video.removeEventListener('pause', hlsPlayerHandlePause);

        video.addEventListener('play', hlsPlayerHandlePlay);
        video.addEventListener('pause', hlsPlayerHandlePause);
    }

    function hlsPlayerWaitForVideoReady(video) {
        return new Promise((resolve) => {
            if (video.readyState >= 3) {
                // HAVE_FUTURE_DATA
                resolve();
            } else {
                const onCanPlay = () => {
                    video.removeEventListener('canplay', onCanPlay);
                    resolve();
                };

                video.addEventListener('canplay', onCanPlay);
            }
        });
    }

    /**
     * Loads the active video, initializes the HlsPlayer, applies the global state, and synchronizes playback position.
     */
    async function hlsPlayerLoadActiveVideo() {
        const { active, inactive } = hlsPlayerDetermineActiveVideo();

        if (active === activeVideo && playerInstance) {
            // No change in active video and playerInstance exists
            return;
        }

        // Save the current playback position if there's an active video
        if (activeVideo) {
            lastPlaybackPosition = activeVideo.currentTime;
        }

        // Update active and inactive videos
        activeVideo = active;
        inactiveVideo = inactive;

        if (playerInstance) {
            // Disconnect HlsPlayer from the previous video
            playerInstance.destroy();
            playerInstance = null;
        }

        if (activeVideo) {
            playerInstance = new hlsPlayer(activeVideo);

            // Apply the mute status based on global state
            if (globalState.isMuted) {
                playerInstance.mute();
            } else {
                playerInstance.unmute();
            }

            // Sync global state with video events
            hlsPlayerSyncGlobalStateWithVideo(activeVideo);

            // Wait until the video is ready before setting the playback position
            try {
                await hlsPlayerWaitForVideoReady(activeVideo);

                // Set the playback position
                await playerInstance.setPlaybackPosition(lastPlaybackPosition);

                // Apply the playback status only if necessary
                if (globalState.isPlaying && activeVideo.paused) {
                    await playerInstance.play();
                } else if (!globalState.isPlaying && !activeVideo.paused) {
                    playerInstance.pause();
                }
            } catch (error) {
                console.error('Error setting playback position or playing video:', error);
            }

            // Update UI button states based on globalState
            hlsPlayerUpdatePlayPauseButton();
            hlsPlayerUpdateMuteUnmuteButton();
        }

        if (inactiveVideo) {
            // Pause the inactive video to prevent both from playing simultaneously
            if (!inactiveVideo.paused) {
                inactiveVideo.pause();
            }
        }
    }
    // Initial load of the active video
    hlsPlayerLoadActiveVideo();

    // Debounce logic for Resize Event

    /**
     * Handles the window resize event with debouncing.
     */
    function hlsPlayerHandleWindowResize() {
        clearTimeout(hlsPlayerResizeTimeout);
        hlsPlayerResizeTimeout = setTimeout(() => {
            hlsPlayerLoadActiveVideo();
        }, 200);
    }

    // Attach the prefixed resize handler to the window resize event as a passive listener
    window.addEventListener('resize', hlsPlayerHandleWindowResize, { passive: true });

    /**
     * Handles the play/pause button click event.
     */
    function hlsPlayerHandlePlayPauseClick() {
        if (playerInstance) {
            if (activeVideo.paused) {
                playerInstance.play();
                globalState.isPlaying = true;
                hlsPlayerUpdatePlayPauseButton();
            } else {
                playerInstance.pause();
                globalState.isPlaying = false;
                hlsPlayerUpdatePlayPauseButton();
            }
        }
    }

    /**
     * Handles the mute/unmute button click event.
     */
    function hlsPlayerHandleMuteUnmuteClick() {
        if (playerInstance) {
            if (activeVideo.muted) {
                playerInstance.unmute();
                globalState.isMuted = false;
                hlsPlayerUpdateMuteUnmuteButton();
            } else {
                playerInstance.mute();
                globalState.isMuted = true;
                hlsPlayerUpdateMuteUnmuteButton();
            }
        }
    }

    if (playPauseBtn) {
        playPauseBtn.addEventListener('click', hlsPlayerHandlePlayPauseClick);
    }

    if (muteUnmuteBtn) {
        muteUnmuteBtn.addEventListener('click', hlsPlayerHandleMuteUnmuteClick);
    }
    /* eslint-enable new-cap */
}

/**
 * Initializes all video-related features on the page.
 */
// eslint-disable-next-line import/group-exports
export function initVideoFeatures() {
    initializeHlsPlayer().catch((error) => {
        console.error('Error initializing HlsPlayer:', error);
    });
}
